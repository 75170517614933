import { Box, BoxProps, Button, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import useMobile from '../../hooks/useMobile'
import { useState } from 'react'
import InstructionModal from '../InstructionModal'

interface ChatInfoSideBarProps extends BoxProps {
  isLoading: boolean
  onRefresh: () => void
}

const ChatInfoSideBar: React.FC<ChatInfoSideBarProps> = ({ onRefresh, isLoading, ...props }) => {
  const isMobile = useMobile()
  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(false)
  const isBetaTrial = true

  const handleOpenInstructionModal = () => {
    setIsInstructionModalOpen(true)
  }

  return (
    <Box
      {...props}
      as='aside'
      h='100vh'
      bg='tertiary'
      color='black'
      p={4}
      position={['fixed', 'fixed', 'relative']}
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={2}
    >
      <Flex justify='space-between' justifyContent={isMobile ? 'center' : 'flex-start'} align='center' mb={4}>
        <Text fontSize='xl' fontWeight='bold'>
          Cody, jouw digitale Timing assistent.
        </Text>
      </Flex>
      <Flex w='100%' h='85%' overflowY='scroll' flexDirection='column' p={3} gap={4}>
        <UnorderedList spacing={4}>
          <ListItem>
            <Text>
              Cody heeft kennis van alle Timing informatie die er op TIPP te vinden is. Dit zijn alle kennisitems en
              werkinstructies over instroom, behoud en uitstroom.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Cody kan geen antwoord geven op klant of flexkracht specifieke vragen, of vragen over andere
              onderwerpen die niet op TIPP saan.
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              Als Cody een verkeerd antwoord geeft, geef hem dan feedback met behulp van de duimpjes onder het
              antwoord
            </Text>
          </ListItem>
          <Text>
            zie:{' '}
            <Text
              as='span'
              color='blue.500'
              textDecoration='underline'
              cursor='pointer'
              onClick={handleOpenInstructionModal}
            >
              Hoe stel ik een goede vraag
            </Text>{' '}
            voor meer tips
          </Text>
        </UnorderedList>
        <Text>
          Gebruik de duimpjes onder het antwoord om feedback te geven op het antwoord. Vooral als Cody een fout
          antwoord geeft, help je ons enorm door dit te laten weten en toe te lichten wat er verkeerd is.
        </Text>
        {isBetaTrial && (
          <Text>
            Als je een technische issue hebt met Cody laat dit dan weten in de Beta Testers Cody Teams chat
          </Text>
        )}
        {!isBetaTrial && <Text>Als je een technische issue hebt met Cody schiet dan een SSD in</Text>}
      </Flex>

      <Box position='sticky' bottom='0' py={[6, 4, 3]} px={6}>
        <Button
          color='white'
          bg='secondary'
          _hover={{
            bg: 'white',
            color: 'secondary',
            border: '1px solid',
            borderColor: 'secondary',
          }}
          _disabled={{ bg: 'secondaryAlpha' }}
          onClick={onRefresh}
          isDisabled={isLoading}
          w='100%'
        >
          Start nieuwe chat
        </Button>
      </Box>
      <InstructionModal isOpen={isInstructionModalOpen} onClose={() => setIsInstructionModalOpen(false)} />
    </Box>
  )
}

export default ChatInfoSideBar
