import { marked } from "marked";




export const parseMarkdownToHTML = (markdownText: string): string => {
    const parsedMarkdownToHTML = marked.parse(markdownText,{
    }) as string;

    const fragments = parsedMarkdownToHTML.split(/\[([^\]]+)\]/g);
    return fragments.join("");
};

export const parseCustomMarkdown = (markdownText: string): string => {
    let parsedMarkdown = marked.parseInline(markdownText,{
    }) as string;
    parsedMarkdown = parsedMarkdown.replace('-', '&#9;-')
    
    return parsedMarkdown;
}

export const parseUrlToHtml = (url: string, text: string) => {
    return `<a href="${url}" style="color: blue; text-decoration: underline;" target="_blank">${text}</a>`;
  };