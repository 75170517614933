import { Box, Button } from '@chakra-ui/react'
import useMobile from '../../hooks/useMobile'

interface Props {
  error: string
  onRetry: () => void
}

export const AnswerError = ({ error, onRetry }: Props) => {
  const isMobile = useMobile()
  return (
    <Box
      pl={3}
      pr={6}
      py={3}
      gap={2}
      borderRadius="0px 16px 16px 0px"
      background="tertiary"
      display="flex"
      alignItems="center"
    >
      <Box color="gray.700" fontSize={isMobile ? "sm" : "md"}>
        {error}   
      </Box>
      <Button
        size='sm'
        borderRadius='full'
        boxShadow='sm'
        bg='secondary'
        color='white'
        border='1px solid'
        px={7}
        py={3}
        _hover={{
          bg: "white",
          color: "secondary",
          border: "1px solid",
          borderColor: "secondary",
        }}
        onClick={onRetry}
      >
        Probeer Opnieuw
      </Button>
    </Box>
  )
}
