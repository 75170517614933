import {
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  UnorderedList,
} from '@chakra-ui/react'

interface InstructionModalProps {
  isOpen: boolean
  onClose: () => void
}

export const tips = [
  {
    instruction:
      'Wees Specifiek: Zorg ervoor dat je vraag duidelijk is. Hoe specifieker je vraag, hoe beter de chatbot je kan helpen.',
    example:
      'In plaats van "Hoe werkt dit?" kun je vragen "Hoe werkt de verzendprocedure voor online bestellingen?"',
  },
  {
    instruction: 'Gebruik volledige zinnen: Probeer een volledige zin te gebruiken om misverstanden te voorkomen.',
    example: '"Hoe maak ik een tariefafspraak aan" in plaats van "tariefafspraak".',
  },
  {
    instruction:
      'Vermijd dubbelzinnigheid: Als er meerdere mogelijke interpretaties van je vraag zijn, probeer dan de vraag te herformuleren om duidelijker te zijn.',
    example:
      'In plaats van "Hoe kan ik het aanmaken?" kun je vragen "Hoe kan ik een plaatsing aanmaken in Cockpit?"',
  },
  {
    instruction:
      'Vraag geen klant/flexkracht specifieke vragen: Cody heeft alleen kennis van de werkinstructies en kennisitems van TIPP, en niet van klant of flexkracht specifieke informatie of andere informatie die niet op TIPP te vinden is.',
    example:
      'Vraag niet: “Hoeveel vakantiedagen heeft Pietje Puk nog?”, maar vraag “Waar kan ik zien hoeveel vakantiedagen mijn flexkracht nog heeft?”',
  },
  {
    instruction:
      'Vraag één ding tegelijk: Stel één vraag per keer. Dit helpt de chatbot om gerichte en nauwkeurige antwoorden te geven.',
    example:
      '"Vraag niet "Hoeveel uur mag een flexkracht per week werken en wanneer wordt een dienst gezien als nachtdienst?”, maar vraag deze twee vragen één voor één.',
  },
]

const InstructionModal: React.FC<InstructionModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', md: 'xl' }}>
      <ModalOverlay />
      <ModalContent minW={{ base: '100vw', md: 800 }} borderRadius={0} h={{ base: '100vh', md: 'auto' }}>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody mb={4} overflowY='auto'>
          <Text mb={4} fontSize='2xl' fontWeight='bold'>
            Hoe schrijf je een prompt?
          </Text>
          <OrderedList spacing={4}>
            {tips.map(({ instruction, example }, index) => (
              <ListItem key={index}>
                <Text>
                  <Text as='b'>{instruction.split(':').at(0)}</Text>: {instruction.split(':').at(1)}
                </Text>
                <UnorderedList>
                  <ListItem>
                    <Text>
                      <Text as='b'>Voorbeeld</Text>: {example}
                    </Text>
                  </ListItem>
                </UnorderedList>
              </ListItem>
            ))}
          </OrderedList>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default InstructionModal
