import { useState } from "react";
import { Box, Stack, Textarea, IconButton } from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa";

interface QuestionInputProps {
  onSend: (question: string) => void;
  disabled: boolean;
  onTyping: () => void;
  onStopTyping: () => void;
  placeholder?: string;
  clearOnSend?: boolean;
}

// TODO: make longer typing

export const QuestionInput: React.FC<QuestionInputProps> = ({
  onSend,
  onTyping,
  onStopTyping,
  disabled,
  placeholder,
  clearOnSend,
}) => {
  const [question, setQuestion] = useState<string>("");

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }
    onSend(question);

    if (clearOnSend) {
      setQuestion("");
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent) => {
    if (ev.key !== "Enter" || ev.nativeEvent.isComposing) {
      return;
    }
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = ev.target.value;

    if (!newValue) {
      setQuestion("");
    } else if (newValue.length <= 1000) {
      setQuestion(newValue);
    }
  };

  const sendQuestionDisabled = disabled || !question.trim();

  return (
    <Stack direction="row" px={0} py={4} width="100%" spacing={4}>
      <Textarea
        borderRadius={24}
        size="xs"
        fontSize="16px"
        width="100%"
        minH={0}
        height={12}
        lineHeight={7}
        px={4}
        placeholder={placeholder}
        bg="tertiary"
        resize="none"
        border="none"
        overflow="hidden"
        focusBorderColor="tertiary"
        value={question}
        onFocus={onTyping}
        onBlur={onStopTyping}
        onChange={onQuestionChange}
        onKeyDown={onEnterPress}
        isDisabled={disabled}
      />
      <Box display="flex" flexDirection="column" justifyContent="center">
        <IconButton
          size="sm"
          icon={<FaArrowRight size={18} />}
          aria-label="Ask question button"
          borderRadius={24}
          bg="secondary"
          _hover={{ bg: 'secondaryAlpha' }}
          color="white"
          onClick={sendQuestion}
          isDisabled={sendQuestionDisabled}
          cursor="pointer"
          opacity={sendQuestionDisabled ? 0.5 : 1}
        />
      </Box>
    </Stack>
  );
};
