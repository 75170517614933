import { Box, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { tips } from '../InstructionModal'
import { BoxProps } from '@chakra-ui/react'

interface AnswerLoaderProps extends BoxProps {}

export const AnswerLoader: React.FC<AnswerLoaderProps> = ({ ...props }) => {
  const [messageIndex, setMessageIndex] = useState(0)
  const [shuffledMessages, setShuffledMessages] = useState<string[]>([])
  const [showSearchMessage, setShowSearchMessage] = useState(true)

  const searhMessage = 'Zoeken door werkinstructies en kennisitems...'

  useEffect(() => {
    const shuffleArray = (array: any[]) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
      }
      return array
    }

    const shuffledTips = shuffleArray(
      tips.map(tip => {
        return (
          <span key={tip.instruction}>
            <Text as='b'>Tip:</Text> {tip.instruction.split(':').at(1)}
          </span>
        )
      }),
    )

    setShuffledMessages([searhMessage, ...shuffledTips])
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setShowSearchMessage(prev => !prev)
      if (showSearchMessage) {
        setMessageIndex(prev => (prev + 1) % shuffledMessages.length)
      }
    }, 6000)

    return () => clearInterval(interval)
  }, [shuffledMessages.length, showSearchMessage])

  return (
    <Box
      {...props}
      ml={showSearchMessage ? props.ml : (props.ml ? Number(props.ml) + 1 : 1)}
      position='relative'
      _after={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background:
          'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%)',
        animation: 'shimmer 2s infinite linear',
        zIndex: 1,
      }}
    >
      <Text
        fontSize='md'
        color='gray.700'
        sx={{
          '@keyframes shimmer': {
            '0%': { transform: 'translateX(-100%)' },
            '100%': { transform: 'translateX(100%)' },
          },
        }}
      >
        {showSearchMessage ? searhMessage : shuffledMessages[messageIndex]}
      </Text>
    </Box>
  )
}
