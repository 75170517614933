import { Flex, FlexProps } from "@chakra-ui/react";
import Lottie from "lottie-react";
import React, { useEffect } from "react";
import typingAnimation from "../static/animations/IsTyping.json";
import loadingAnimation from "../static/animations/Loading.json";
import standbyAnimation from "../static/animations/Standby.json";

interface ChatHeaderProps extends FlexProps {
  isTyping: boolean;
  isLoading: boolean;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  isTyping,
  isLoading,
  ...props
}) => {
  const animationRef = React.useRef<any>(null);
  const [animationData, setAnimationData] =
    React.useState<any>(standbyAnimation);

  useEffect(() => {
    if (isTyping) {
      setAnimationData(typingAnimation);
    } else if (isLoading) {
      setAnimationData(loadingAnimation);
    } else {
      setAnimationData(standbyAnimation);
    }
  }, [isLoading, isTyping]);

  return (
    <Flex
      {...props}
      as="header"
      align="center"
      justify="space-around"
      wrap="wrap"
      bg="primary"
      color="white"
    >
      <Flex align="center" justify="center">
        <Lottie
          animationData={animationData}
          lottieRef={animationRef}
          autoplay={true}
          loop={true}
          style={{
            flexBasis: String(props.h) ?? "12%",
          }}
        />
      </Flex>
    </Flex>
  );
};

export default ChatHeader;
