import { Box } from "@chakra-ui/react";
import './answer.css';

interface AnswerTextProps {
  htmlContent?: string;
  children?: React.ReactNode;
}

const AnswerText: React.FC<AnswerTextProps> = ({ htmlContent, children }) => {
  return (
    <>
      {htmlContent ? (
        <Box
          pl={3}
          pr={6}
          py={3}
          borderRadius="0px 16px 16px 0px"
          background="tertiary"
          fontSize="16px"
          fontWeight="400"
          lineHeight="1.2em"
          whiteSpace="pre-line"
          minWidth={["60%", "60%", "50%"]}
          maxWidth={["80%", "80%", "70%"]}
          display="flex"
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          sx={{
            "& table": {
              borderCollapse: "collapse",
            },
            "& td, & th": {
              border: "1px solid",
              padding: "5px",
            },
            "& ul": {
              marginLeft: "20px",
            },
          }}
        />
      ) : (
        <Box
          p={8}
          py={4}
          background="rgb(249, 249, 249)"
          borderRadius={8}
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)"
          fontSize="16px"
          fontWeight="400"
          lineHeight="22px"
          whiteSpace="pre-line"
          maxWidth="60%"
          display="flex"
          minWidth="500px"
          sx={{
            "& table": {
              borderCollapse: "collapse",
            },
            "& td, & th": {
              border: "1px solid",
              padding: "5px",
            },
            "> p": {
              margin: 0,
            },
          }}
        >
          {children}
        </Box>
      )}
    </>
  );
};

export default AnswerText;