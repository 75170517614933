import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import React from 'react'
import ChatPage from './ChatPage'
import LoginPage from './LoginPage'

const ProtectedChatPage: React.FC = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <ChatPage />
      </AuthenticatedTemplate>
    </>
  )
}

export default ProtectedChatPage
