import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Grid,
  Textarea,
  Text,
} from "@chakra-ui/react";
import { AnswerFeedback } from "../../types/chatTypes";
import { useEffect, useState } from "react";

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (feedback: AnswerFeedback) => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [reason, setReason] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [feedBackIsSent, setFeedbackIsSent] = useState<boolean>(false);

  const reasons = ["Antwoord klopt niet", "Bron klopt niet", "Anders..."];

  useEffect(() => {
    setReason("");
    setFeedback("");
    setFeedbackIsSent(false);
  }, [isOpen]);

  const onSubmitFeedback = () => {
    setFeedbackIsSent(true);
    onSubmit({ reason, feedback });
    setTimeout(() => {
      onClose()
    }, 1000);
  };


  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full", md: "md" }}>
      <ModalOverlay />
      <ModalContent borderRadius={0} h={{ base: "100vh", md: "auto" }}>
        <ModalHeader>
          <ModalCloseButton alignSelf="flex-start" />
        </ModalHeader>
        {feedBackIsSent ? (
          <ModalBody>
            <Text mb={4} fontSize="2xl" fontWeight="bold">
              Uw feedback is verzonden! ✅
            </Text>
          </ModalBody>
        ) : (
          <>
            <ModalBody>
              <Text fontSize="2xl" fontWeight="bold">
                Geef feedback op de vraag
              </Text>
              <Grid mt={4} templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
                {reasons.map((r) => (
                  <Button
                    key={r}
                    onClick={() => setReason(r)}
                    borderRadius={16}
                    fontSize="sm"
                    textTransform="none"
                    justifyContent="flex-start"
                    fontWeight="light"
                    bg={r === reason ? "primary" : "gray.200"}
                    color={r === reason ? "white" : "black"}
                    _hover={{ bg: "primary", color: "white" }}
                  >
                    {r}
                  </Button>
                ))}
              </Grid>
            </ModalBody>
            <ModalFooter flexDirection={{ base: "column", md: "row" }} justifyContent="center" alignItems="stretch">
              <Textarea
                placeholder="Vul hier je feedback in"
                resize="none"
                overflow="auto"
                onChange={(event) => setFeedback(event.target.value)}
                mb={{ base: 4, md: 0 }}
              />
              <Button
                ml={{ base: 0, md: 4 }}
                mt={{ base: 4, md: 4 }}
                mb={[ 6, 0, 0]}
                color="white"
                bg="secondary"
                _hover={{
                  bg: "white",
                  color: "secondary",
                  border: "1px solid",
                  borderColor: "secondary",
                }}
                variant="solid"
                onClick={onSubmitFeedback}
              >
                Verzenden
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;
