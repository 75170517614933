import { Box, Button, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import coverBackground from '../static/images/background.svg'
import microsoftLogo from '../static/images/microsoft.png'
import { useAppContext } from '../context/AppContext'

const LoginPage: React.FC = () => {
  const { signIn } = useAppContext()

  return (
    <Flex
      flexDirection='column'
      width='100wh'
      height='100vh'
      bg='primary'
      justifyContent='center'
      alignItems='center'
      bgImage={coverBackground}
      bgSize='cover'
      bgPosition='center'
    >
      <Stack flexDir='column' mb='2' justifyContent='center' alignItems='center'>
        <Heading color='white'>Welkom Tester</Heading>
        <Box width={{ base: '80%', md: '400px' }} p={6}>
          <Stack spacing={4} p='1rem' bg='tertiary'>
            <Flex align='center' gap={10}>
              <Box>
                <Text>Welkom bij het Cody test team!</Text>
                <Text>Log in met je Microsoft account om te beginnen.</Text>
              </Box>
            </Flex>

            <Button
              leftIcon={<Image src={microsoftLogo} alt='Microsoft logo' boxSize='24px' />}
              borderRadius={0}
              variant='solid'
              bgColor='gray.100'
              fontSize='20px'
              width='full'
              onClick={signIn}
              color='white'
              bg='secondary'
              _hover={{
                bg: 'white',
                color: 'secondary',
                border: '1px solid',
                borderColor: 'secondary',
              }}
            >
              Login met Microsoft
            </Button>
          </Stack>
        </Box>
      </Stack>
      <Box position='absolute' bottom={0}>
        <svg width='72' height='72' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 756.01 756.01'>
          <path fill='#3ABACB' d='M756.012 756.013H0V.001h756.012v756.012'></path>
          <path
            fill='#fff'
            d='M164.559 311.508l.153-.93H56.418l-6.076 33.44-.164.931h35.235L67.965 444.5l-.155.93h38.596l17.787-100.48H158.7l5.86-33.441m11.659-.932L152.407 445.43h38.792l23.812-134.852h-38.792m156.288-.001l-38.216 73.395-12.996-72.741-.123-.654h-48.94l-23.81 134.852h36.867l11.896-70.906 13.016 70.258.124.648h27.194l37.825-69.754-12.615 68.818-.165.936h38.104l23.81-134.852h-51.971m69.203 0L377.9 445.43h38.793l23.81-134.852h-38.791m125.978 72.139l-29.766-71.65-.205-.49h-39.995l-23.81 134.852H472.2l12.408-72.921 30.054 72.433.205.488h39.635l23.812-134.852h-38.268l-12.358 72.14M637.719 394.7l-.164.936h24.592l-2.766 16.07a26.948 26.948 0 01-5.222 1.455c-2.755.488-5.84.798-9.16.919-4.72-.008-8.946-.713-12.554-2.101-3.558-1.383-6.549-3.306-8.905-5.718-2.384-2.436-4.193-5.356-5.387-8.682-1.244-3.496-1.881-7.326-1.881-11.392 0-5.958 1.008-11.684 3.012-17.015 1.984-5.33 4.77-10.081 8.267-14.112a39.17 39.17 0 0112.286-9.505c8.843-4.348 21.386-4.6 28.542-.555 3.485 1.98 6.25 4.427 8.214 7.275l2.899 4.184 28.325-25.534-2.446-3.269c-5.13-6.833-12.226-12.025-21.087-15.439-18.467-7.165-42.061-6.616-60.156 1.414-9.573 4.257-18.013 10.19-25.088 17.633-7.073 7.464-12.645 16.296-16.553 26.259-3.927 9.942-5.922 20.722-5.922 32.037 0 9.016 1.594 17.268 4.719 24.52 3.137 7.325 7.61 13.655 13.284 18.805 5.624 5.122 12.523 9.115 20.49 11.86 7.825 2.699 16.605 4.067 26.086 4.067 8.883 0 17.601-1.065 25.897-3.152 8.276-2.09 15.876-4.91 22.579-8.384l1.973-1.024 12.2-70.257H643l-2.628 14.284-2.652 14.421'
          ></path>
        </svg>
      </Box>
    </Flex>
  )
}

export default LoginPage
