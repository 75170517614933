import { Event, EventSourcePolyfill } from 'event-source-polyfill'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getChatStreamerEvent } from '../api/api'
import { useAppContext } from '../context/AppContext'
import { ChatStreamRequest } from '../types/chatTypes'

interface ErrorEvent extends Event {
  type: 'error'
  status: number
  statusText: string
  error?: undefined
}

export function useChatStream(
  chatStreamRequest: Omit<ChatStreamRequest, 'message'>,
  onMessageComplete: (message: string, tempId: string, messageId: string, isSensitive: boolean) => void,
  onStreamingUpdate: (partialMessage: string, tempId: string, messageId?: string, error?: Error) => void,
) {
  const [streamingAnswer, setStreamingAnswer] = useState('')
  const [isSensitiveAnswer, setIsSensitiveAnswer] = useState(false)
  const [newMessageId, setNewMessageId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const { refreshUser } = useAppContext()

  const streamingAnswerRef = useRef('')
  const isSensitiveAnswerRef = useRef(false)
  const newMessageIdRef = useRef('')
  const sourceRef = useRef<EventSourcePolyfill | null>(null)

  const sendMessage = useCallback(
    async (message: string, tempId: string) => {
      try {
        setIsLoading(true)
        setError(null)
        setStreamingAnswer('')
        streamingAnswerRef.current = ''

        if (sourceRef.current) {
          sourceRef.current.close()
        }

        const source = await getChatStreamerEvent({ ...chatStreamRequest, message })
        if (!source) {
          throw new Error('Could not establish connection. Please try again.')
        }
        sourceRef.current = source

        source.onopen = () => {
          setIsLoading(false)
        }

        source.onmessage = event => {
          const payload = JSON.parse(event.data)
          streamingAnswerRef.current += payload.message
          setStreamingAnswer(streamingAnswerRef.current)

          isSensitiveAnswerRef.current = payload.is_sensitive
          setIsSensitiveAnswer(payload.is_sensitive)

          newMessageIdRef.current = payload.message_id
          setNewMessageId(payload.message_id)
          onStreamingUpdate(streamingAnswerRef.current, tempId, payload.message_id)
        }

        source.onerror = error => {
          const errorEvent = error as ErrorEvent
          if (errorEvent && errorEvent.status) {
            let errorMessage = 'An unknown error occurred'
            if (errorEvent.status === 401) {
              refreshUser && refreshUser()
              errorMessage = `${errorEvent.statusText}. Probeer opnieuw`
            } else {
              errorMessage = `${errorEvent.statusText}. Contacteer support`
            }
            const backendError = new Error(errorMessage)
            setError(backendError)
            onStreamingUpdate('', tempId, undefined, backendError)
          } else if (errorEvent?.error) {
            const backendError = new Error(errorEvent?.error)
            setError(backendError)
            onStreamingUpdate('', tempId, undefined, backendError)
          }
          setIsLoading(false)
          onMessageComplete(
            streamingAnswerRef.current,
            tempId,
            newMessageIdRef.current,
            isSensitiveAnswerRef.current,
          )
          setStreamingAnswer('')
          source.close()
          sourceRef.current = null
        }
      } catch (error) {
        setError(error as Error)
        setIsLoading(false)
        onStreamingUpdate('', tempId, undefined, error as Error)
        if (sourceRef.current) {
          sourceRef.current.close()
          sourceRef.current = null
        }
      }
    },
    [refreshUser, chatStreamRequest, onMessageComplete, onStreamingUpdate],
  )

  useEffect(() => {
    return () => {
      if (sourceRef.current) {
        sourceRef.current.close()
      }
    }
  }, [])

  return {
    streamingAnswer,
    isSensitiveAnswer,
    newMessageId,
    isLoading,
    error,
    sendMessage,
  }
}
