import { Box, Icon, Image } from '@chakra-ui/react'
import DOMPurify from 'dompurify'
import { useMemo, useState } from 'react'
import { IoMdWarning } from 'react-icons/io'
import BotIcon from '../../static/images/botIcon.svg'

import { FiAlertCircle } from 'react-icons/fi'
import { IoMdThumbsDown, IoMdThumbsUp } from 'react-icons/io'
import { updateMessage } from '../../api/api'
import { useAppContext } from '../../context/AppContext'
import { parseMarkdownToHTML } from '../../parsers/markdownParser'
import { AnswerFeedback, AskResponse } from '../../types/chatTypes'
import { AnswerError } from './AnswerError'
import { AnswerLoader } from './AnswerLoader'
import AnswerText from './AnswerText'
import FeedbackModal from './FeedbackModal'

interface Props {
  answer: AskResponse
  isLoading?: boolean
  withFeedback?: boolean
  onRetry: () => void
}

const parseAnswerToHtml = (answer: AskResponse): string => {
  let htmlText = '<div style="display: flex; flex-direction: column; align-items: flex-start; gap: 1rem;">'
  if (answer.is_sensitive) {
    htmlText +=
      ' <p style="color: orange; margin: 0;"><strong>Let op: Gevoelig onderwerp, extra antwoord controle vereist.</strong></p>'
  }
  htmlText += parseMarkdownToHTML(answer.answer ?? '')
  htmlText += '</div>'
  return htmlText
}

export const Answer = ({ answer, withFeedback, isLoading, onRetry }: Props) => {
  const [isDisliked, setIsDisliked] = useState(false)
  const [isLiked, setIsLiked] = useState(false)
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false)
  const app = useAppContext()

  const htmlAnswer = useMemo(() => parseAnswerToHtml(answer), [answer])

  const sanitizedAnswerHtml = DOMPurify.sanitize(htmlAnswer, {
    ALLOWED_ATTR: ['href', 'style', 'text-decoration', 'target'],
  })

  const dislikeAnswer = () => {
    if (isDisliked) {
      updateMessage({
        id: answer.message_id,
        accessToken: app.accessToken,
        isBadResponse: false,
        isGoodResponse: false,
        reasonBadResponse: '',
        feedbackBadResponse: '',
      })
      setIsDisliked(false)
      setIsLiked(false)
    } else {
      setIsFeedbackOpen(true)
    }
  }

  const handleCloseFeedbackModal = () => {
    setIsFeedbackOpen(false)
  }

  const handleSubmitFeedback = (feedback: AnswerFeedback) => {
    updateMessage({
      id: answer.message_id,
      accessToken: app.accessToken,
      isBadResponse: true,
      isGoodResponse: false,
      reasonBadResponse: feedback.reason,
      feedbackBadResponse: feedback.feedback,
    })
    setIsDisliked(true)
    setIsLiked(false)
  }

  const likeAnswer = () => {
    if (isLiked) {
      updateMessage({
        id: answer.message_id,
        accessToken: app.accessToken,
        isBadResponse: isDisliked,
        isGoodResponse: false,
        reasonBadResponse: '',
        feedbackBadResponse: '',
      })
      setIsLiked(false)
    } else {
      updateMessage({
        id: answer.message_id,
        accessToken: app.accessToken,
        isBadResponse: false,
        isGoodResponse: true,
        reasonBadResponse: '',
        feedbackBadResponse: '',
      })
      setIsLiked(true)
      setIsDisliked(false)
    }
  }

  return (
    <>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' flex='1'>
          <Box display='flex' flexDirection='column' zIndex={isLoading ? 2: -1} background={isLoading ? 'transparent' : 'tertiary'}>
            <Image ml={2} mt={2} alignSelf='flex-start' src={BotIcon} alt='C' boxSize={5} />
            {answer?.is_sensitive && <Icon as={IoMdWarning} fill='orange' ml={2} mt={2} boxSize={5} />}
            {answer.error && <Icon as={FiAlertCircle} color='red' ml={2} mt={2} mb={2}boxSize={5} />}
          </Box>
          {isLoading ? (
            <AnswerLoader mt={1.5} ml={2} />
          ) : answer.error ? (
            <AnswerError error={answer.error} onRetry={onRetry} />
          ) : (
            <AnswerText htmlContent={sanitizedAnswerHtml} />
          )}
        </Box>
        {withFeedback && !isLoading && !answer.error && (
          <Box display='flex' flexDirection='row' gap={2} mt={1} ml={2}>
            <Box>
              <Icon
                style={{ cursor: 'pointer' }}
                onClick={likeAnswer}
                as={IoMdThumbsUp}
                w={6}
                h={6}
                color={`gray.${300 + (isLiked ? 600 : 0)}`}
              />
            </Box>
            <Box>
              <Icon
                style={{ cursor: 'pointer' }}
                onClick={dislikeAnswer}
                as={IoMdThumbsDown}
                w={6}
                h={6}
                color={`gray.${300 + (isDisliked ? 600 : 0)}`}
              />
            </Box>
          </Box>
        )}
      </Box>
      <FeedbackModal isOpen={isFeedbackOpen} onClose={handleCloseFeedbackModal} onSubmit={handleSubmitFeedback} />
    </>
  )
}
