import { Configuration, LogLevel, PopupRequest } from '@azure/msal-browser'

let clientID: string;
let redirectUri: string;

if (process.env.REACT_APP_BUILD_ENV === "production") {
  clientID = "ffe1956b-2026-4547-980c-4afb221d823d"
  redirectUri = "https://cody.timing.nl/"
} else {
  clientID = "6846866c-8af9-4bdc-9c00-482feda354d1"
  redirectUri = "https://acc-vraaghetaantipp-frontend.azurewebsites.net/"
}


export const msalConfig: Configuration = {
  auth: {
    clientId: clientID,
    redirectUri: redirectUri,
    authority: "https://login.microsoftonline.com/88ff25df-67ea-4f86-b4d6-592bceace168" 
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    tokenRenewalOffsetSeconds: 300,
    allowRedirectInIframe: false,
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        switch (logLevel) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      },
    },
  },
}


export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
}
