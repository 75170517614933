import { Avatar, Box, Text } from "@chakra-ui/react";
import { useAppContext } from "../context/AppContext";

interface Props {
  message: string;
}

export const UserChatMessage = ({ message }: Props) => {
  const app = useAppContext();
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      my={5}
      ml="auto"
      maxWidth="80%"
    >
      <Text p={5} borderRadius={8} maxWidth="80%">
        {message}
      </Text>
      <Avatar
        color="white"
        mr={4}
        size="sm"
        bg="primary"
        name={app.user?.displayName || "Anoniem"}
        src={app.user?.avatar}
      />
    </Box>
  );
};
