import { EventSourcePolyfill } from 'event-source-polyfill'
import { AskResponse, ChatHistory, ChatStreamRequest, UpdateMessageRequest, UserInfo } from '../types/chatTypes'
import { toast } from 'react-toastify'

let backendUrl: string

if (process.env.REACT_APP_BUILD_ENV === 'production') {
  backendUrl = 'https://prd-cody-backend.azurewebsites.net/'
} else {
  backendUrl = 'https://acc-vraaghetaantipp.azurewebsites.net/'
}

export async function getHeaders(accessToken: string | undefined): Promise<Record<string, string>> {
  if (accessToken) {
    return { Authorization: `Bearer ${accessToken}` }
  } else {
    toast.error(`Authenticatie fout, probeer opnieuw`)
  }

  return {}
}

export async function getChatStreamerEvent({
  conversationId,
  message,
  accessToken,
}: ChatStreamRequest): Promise<EventSourcePolyfill> {
  try {
    const headers = await getHeaders(accessToken)
    const params = new URLSearchParams({
      message: message,
      conversation_id: conversationId || '',
    })
    const url = `${backendUrl}api/chat/stream?${params.toString()}`

    // First, try to fetch the URL to check if it's reachable
    await fetch(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
    })

    // If fetch succeeds, create and return the EventSource
    return new EventSourcePolyfill(url, {
      headers: headers,
      withCredentials: true,
    })
  } catch (error) {
    console.error('Error creating EventSource:', error)
    throw new Error('Failed to connect to the server. Please check your network connection and try again.')
  }
}

export async function updateMessage(message: UpdateMessageRequest): Promise<AskResponse> {
  const response = await fetch(`${backendUrl}api/chat/messages/${message.id}`, {
    method: 'PATCH',
    headers: {
      Authorization: 'Bearer ' + message.accessToken || '',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      is_bad_response: message.isBadResponse,
      is_good_response: message.isGoodResponse,
      reason_bad_response: message.reasonBadResponse,
      feedback_bad_response: message.feedbackBadResponse,
    }),
  })

  const parsedResponse: AskResponse = await response.json()
  if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || 'Unknown error')
  }

  return parsedResponse
}

export async function fetchChatHistory(accessToken?: string | undefined): Promise<ChatHistory> {
  const response = await fetch(`${backendUrl}api/chat/history`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + accessToken || '',
      'Content-Type': 'application/json',
    },
  })

  const parsedResponse: ChatHistory = await response.json()
  if (response.status > 299 || !response.ok) {
    throw Error('Error retrieving history')
  }

  return parsedResponse
}

export async function userApi({ accessToken }: Omit<ChatStreamRequest, 'message'>): Promise<UserInfo> {
  const response = await fetch(`${backendUrl}auth/user`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + accessToken || '',
      'Content-Type': 'application/json',
    },
  })

  const parsedResponse: UserInfo = await response.json()
  if (response.status === 401) {
    throw Error('Authenticatie fout, probeer opnieuw')
  } else if (response.status > 299 || !response.ok) {
    throw Error(parsedResponse.error || 'Onbekende fout')
  }

  return parsedResponse
}
