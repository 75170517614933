import { Box, Flex, Icon, IconButton, Link } from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { PiSidebar } from 'react-icons/pi'
import { v4 as uuidv4 } from 'uuid'
import ChatBubble from '../components/ChatBubble'
import ChatHeader from '../components/ChatHeader'
import InstructionModal from '../components/InstructionModal'
import { QuestionInput } from '../components/QuestionInput'
import ChatInfoSideBar from '../components/SideBars/ChatInfoSideBar'
import { useAppContext } from '../context/AppContext'
import { useChatStream } from '../hooks/useChatStream'
import useMobile from '../hooks/useMobile'
import { AskResponse } from '../types/chatTypes'

function generateWelcomeMessage(
  userName: string,
  sessionId: string,
): { tempId: string; question: string | undefined; answer: AskResponse } {
  const tempMessageId = uuidv4()
  const firstName = userName.split(' ')[0] || ''
  const welcomeMessage = `Hi ${firstName}! Ik ben Cody, jouw digitale Timing assistent. Ik kan je helpen bij jouw vragen over instroom, behoud en/of uitstroom uit de werkinstructies en kennisitems. Wat wil je weten?`
  return {
    tempId: tempMessageId,
    question: undefined,
    answer: {
      message_id: sessionId,
      answer: welcomeMessage,
      is_sensitive: undefined,
      isStreaming: false,
    },
  }
}

const ChatPage = () => {
  const [openInstruction, setOpenInstruction] = useState<boolean>(false)
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [answers, setAnswers] = useState<{ tempId: string; question: string | undefined; answer: AskResponse }[]>([])
  const isMobile = useMobile()
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(!isMobile)
  const app = useAppContext()

  useEffect(() => {
    if (!app?.user) return
    setAnswers([generateWelcomeMessage(app.user?.displayName || '', app?.sessionId || '')])
  }, [app.user, app.sessionId])

  const handleMessageComplete = useCallback(
    (completedMessage: string, tempId: string, messageId: string, isSensitive: boolean) => {
      setAnswers(prevAnswers =>
        prevAnswers.map(answer =>
          answer.tempId === tempId
            ? {
                ...answer,
                answer: {
                  ...answer.answer,
                  message_id: messageId,
                  is_sensitive: isSensitive,
                  answer: completedMessage,
                  isStreaming: false,
                },
              }
            : answer,
        ),
      )
    },
    [],
  )

  const handleStreamingUpdate = useCallback(
    (partialMessage: string, tempId: string, messageId?: string, error?: Error) => {
      setAnswers(prevAnswers =>
        prevAnswers.map(answer =>
          answer.tempId === tempId
            ? {
                ...answer,
                answer: {
                  ...answer.answer,
                  message_id: messageId || answer.answer.message_id,
                  answer: error ? '' : partialMessage,
                  isStreaming: !error,
                  error: error?.message,
                },
              }
            : answer,
        ),
      )
    },
    [],
  )

  const { isLoading, sendMessage } = useChatStream(
    {
      accessToken: app.accessToken,
      conversationId: app.sessionId || '',
    },
    handleMessageComplete,
    handleStreamingUpdate,
  )

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      if (elementRef.current) {
        elementRef.current.scrollIntoView()
      }
    }, [])
    return <div ref={elementRef} />
  }

  const handleSendMessage = (message: string) => {
    const tempMessageId = uuidv4()
    const newAnswer: AskResponse = {
      message_id: tempMessageId,
      is_sensitive: undefined,
      answer: '',
      isStreaming: true,
    }
    setAnswers([...answers, { tempId: tempMessageId, question: message, answer: newAnswer }])
    sendMessage(message, tempMessageId)
  }

  const clearChat = () => {
    if (!app?.user) return
    setAnswers([generateWelcomeMessage(app.user?.displayName || '', app?.sessionId || '')])
    isMobile && setIsSidebarOpen(false)
  }

  return (
    <Flex width='100%' flexDirection='row'>
      <Box display={['block', 'block', 'none']} borderRadius={0}>
        <IconButton
          aria-label='Expand sidebar'
          icon={<Icon as={PiSidebar} />}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          position='fixed'
          color={isSidebarOpen ? 'black' : 'white'}
          _hover={{
            bgColor: 'transparent',
          }}
          bgColor='transparent'
          borderRadius={0}
          zIndex={3}
        />
      </Box>
      <ChatInfoSideBar
        w={['100%', '100%', '25%']}
        display={[isSidebarOpen ? 'block' : 'none', isSidebarOpen ? 'block' : 'none', 'block']}
        isLoading={isLoading}
        onRefresh={clearChat}
      />
      <Flex w={['100%', '100%', '75%']} h='100vh' flexDirection='column' ml={[0, 0, isSidebarOpen ? '25%' : 0]}>
        <ChatHeader h='15%' isTyping={isTyping} isLoading={isLoading} />
        <Flex h='85%' flexDir='column' px={[3, 3, 6]}>
          <Flex h='84%' overflowY='scroll' overflowX='hidden' flexDirection='column' py={6} px={3}>
            {answers.map((answer, index) => {
              return (
                <ChatBubble
                  key={`answer${index}`}
                  answer={answer}
                  isStreaming={answer.answer.isStreaming}
                  onRetry={() => {
                    if (answer.question) {
                      handleSendMessage(answer.question)
                    }
                  }}
                />
              )
            })}
            <AlwaysScrollToBottom />
          </Flex>
          <Box display='flex' flexDirection='column' position='sticky' bottom='0'>
            <Link
              alignSelf='flex-end'
              onClick={() => setOpenInstruction(true)}
              style={{ textDecoration: 'underline' }}
              mr={8}
            >
              Hoe stel ik een goede vraag voor meer tips
            </Link>
            <QuestionInput
              clearOnSend
              placeholder='Stel je vraag hier'
              disabled={isLoading}
              onTyping={() => setIsTyping(true)}
              onStopTyping={() => setIsTyping(false)}
              onSend={question => {
                setIsTyping(false)
                handleSendMessage(question)
              }}
            />
          </Box>
        </Flex>
      </Flex>
      <InstructionModal isOpen={openInstruction} onClose={() => setOpenInstruction(false)} />
    </Flex>
  )
}

export default ChatPage
