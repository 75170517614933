import { extendTheme } from "@chakra-ui/react";

const clientTheme = extendTheme({
  colors: {
    primary: "#3ABACB",
    secondary: "#E93F6B",
    secondaryAlpha: "#F897AF",
    tertiary: "#F4F5FC",
    primaryScheme: {
      50: "#e0f7f9",
      100: "#b3eef3",
      200: "#80e4ed",
      300: "#4ddae7",
      400: "#1ad0e1",
      500: "#3ABACB",
      600: "#009ba8",
      700: "#007984",
      800: "#005960",
      900: "#00393c",
    },
  },
});

export default clientTheme;