import { InteractionType, PublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser'
import React, { MouseEventHandler, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getProfilePhoto, getUser } from '../services/GraphService'

export interface AppUser {
  displayName?: string
  email?: string
  avatar?: string
}

export interface AppError {
  message: string
}

type AppContext = {
  accessToken?: string
  user?: AppUser
  error?: AppError
  sessionId?: string
  signIn?: MouseEventHandler<HTMLElement>
  signOut?: MouseEventHandler<HTMLElement>
  refreshUser?: () => void
  displayError?: (message: string) => void
  clearError?: () => void
  authProvider?: AuthCodeMSALBrowserAuthenticationProvider
  isAuthenticated?: boolean
}

const appContext = createContext<AppContext>({
  user: undefined,
  error: undefined,
  accessToken: undefined,
  sessionId: undefined,
  signIn: undefined,
  signOut: undefined,
  displayError: undefined,
  clearError: undefined,
  authProvider: undefined,
})

export function useAppContext(): AppContext {
  return useContext(appContext)
}

interface ProvideAppContextProps {
  children: React.ReactNode
}

export default function ProvideAppContext({ children }: ProvideAppContextProps) {
  const auth = useProvideAppContext()
  return <appContext.Provider value={auth}>{children}</appContext.Provider>
}

function useProvideAppContext() {
  const { instance: msalInstance } = useMsal()
  const [user, setUser] = useState<AppUser | undefined>(undefined)
  const [sessionId, setSessionId] = useState<string | undefined>(undefined)
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined)
  const [tokenExpiresOn, setTokenExpiresOn] = useState<Date | undefined>(undefined)
  const [error, setError] = useState<AppError | undefined>(undefined)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const displayError = (message: string) => {
    setError({ message })
  }

  const clearError = () => {
    setError(undefined)
  }

  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalInstance as PublicClientApplication, {
    account: msalInstance.getActiveAccount()!,
    scopes: ['user.read'],
    interactionType: InteractionType.Popup,
  })

  

  const refreshToken = useCallback(async () => {
    console.log('refreshing authorization...')
    const account = msalInstance.getActiveAccount()
    if (account) {
      try {
        const accessTokenResponse = await msalInstance.acquireTokenSilent({
          scopes: ['user.read'],
          account: account,
          forceRefresh: true,
        })
        console.log('new token expires on', accessTokenResponse.expiresOn)
        setAccessToken(accessTokenResponse.accessToken)
        setTokenExpiresOn(accessTokenResponse.expiresOn as Date)
      } catch (error) {
        console.error('Failed to refresh token:', error)
      }
    }
  }, [msalInstance])

  useEffect(() => {
    if (tokenExpiresOn) {
      const timeUntilExpiry = tokenExpiresOn.getTime() - Date.now()
      const timeToRefresh = timeUntilExpiry - 3 * 60 * 1000 // 3 minutes before expiry

      const refreshTimerId = setTimeout(() => {
        refreshToken()
      }, timeToRefresh)

      return () => clearTimeout(refreshTimerId)
    }
  }, [tokenExpiresOn, refreshToken])

  useEffect(() => {
    const checkUser = async () => {
      if (!user) {
        try {
          const account = msalInstance.getActiveAccount()
          if (account) {
            const user = await getUser(authProvider)
            try {
              const profilePhoto = await getProfilePhoto(authProvider)
              setUser({
                displayName: user.displayName || '',
                email: user.mail || '',
                avatar: profilePhoto || '',
              })
            } catch (err) {
              setUser({
                displayName: user.displayName || '',
                email: user.mail || '',
              })
            }
            msalInstance
              .acquireTokenSilent({
                scopes: ['user.read'],
                account: account,
                forceRefresh: true,
              })
              .then(accessTokenResponse => {
                setAccessToken(accessTokenResponse.accessToken)
                setTokenExpiresOn(accessTokenResponse.expiresOn as Date)
              })
            setSessionId(uuidv4())
          }
        } catch (err: any) {
          console.error('error in checking user', err)
          displayError(err.message)
        }
      }
    }
    console.log('checking user')
    checkUser()
  })

  const signIn = async () => {
    await msalInstance.loginPopup({
      scopes: ['user.read'],
      prompt: 'select_account',
    })
    const user = await getUser(authProvider)

    setUser({
      displayName: user.displayName || '',
      email: user.mail || '',
    })
    setIsAuthenticated(true)
  }

  const signOut = async () => {
    await msalInstance.logoutPopup()
    setUser(undefined)
    setIsAuthenticated(false)
  }

  return {
    user,
    error,
    accessToken,
    sessionId,
    signIn,
    signOut,
    refreshUser: refreshToken,
    displayError,
    clearError,
    authProvider,
    isAuthenticated,
  }
}
