import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'
import { AskResponse } from '../types/chatTypes'
import { Answer } from './Answer/Answer'
import { UserChatMessage } from './UserChatMessage'

interface ChatBubbleProps extends BoxProps {
  answer: { tempId: string; question: string | undefined; answer: AskResponse }
  isStreaming: boolean
  onRetry: () => void
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ answer, isStreaming, onRetry, ...props }) => {
  return (
    <Box {...props}>
      {answer.question && <UserChatMessage message={answer.question} />}
      <Answer
        {...props}
        answer={answer.answer}
        withFeedback={answer.question !== undefined}
        isLoading={isStreaming && answer.answer.answer === ''}
        onRetry={onRetry}
      />
    </Box>
  )
}

export default ChatBubble
