import { Box, ChakraProvider } from '@chakra-ui/react'

import { ToastContainer, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AppContext from './context/AppContext'
import ProtectedChatPage from './pages/ProtectedChatPage'
import clientTheme from './themes/clientTheme'

export const App = () => (
  <ChakraProvider theme={clientTheme}>
    <AppContext>
      <Box h='100vh'>
        <ProtectedChatPage />
        <ToastContainer
          position='top-right'
          autoClose={10000}
          hideProgressBar={true}
          transition={Zoom}
          pauseOnHover
        />
      </Box>
    </AppContext>
  </ChakraProvider>
)
